<template>
  <div />
</template>

<script>
export default {
  name: 'Logout',
  mounted() {
    this.$router.push({ name: 'login' });
  },
};
</script>
